.user.ant-notification-notice {
  padding: 10px;
  background: #e1f5f0;
  border: 2px solid #009e80;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.16);
  min-width: 450px;
}

.user .ant-notification-notice-message {
  margin-bottom: 0px;
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
  color: #666666;
  min-width: 500px;
}

.user .ant-notification-notice-close {
  width: 25px;
  height: 25px;
  top: 25%;
  inset-inline-end: 10px;
}
