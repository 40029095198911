@import url("./common//styles//add-popup-builder.css");
@import url("./common//styles/delete-user-popup.css");
@import url("./common//styles/user-toast.css");

body,
button,
div,
span,
.ant-btn,
li,
ul {
  margin: 0;
  font-family: "Be Vietnam Pro", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-layout {
  padding: 15px;
  padding-bottom: 0px;
}

.ant-table-column-sorter {
  display: none;
}

.ant-modal-mask {
  background: rgba(255, 255, 255, 0.88) !important;
  backdrop-filter: blur(2px) !important;
}
.ant-layout-header {
  position: sticky;
  top: 0px;
  z-index: 1;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 0px;
  line-height: unset;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-layout-footer {
  /* position: fixed;
  bottom: 0;
  width: 98%; */
  font-weight: 400;
  font-size: 16px;
  color: #888888;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px 0px;
  margin-top: 20px;
}

.header-dropdown ul {
  padding: 0px !important;
}
.pick-dropdown {
  margin-top: -73px !important;
  padding: 0px !important;
}
.header-dropdown ul li {
  font-weight: 500 !important;
  font-size: 16px !important;
  text-align: center !important;
  color: #003c3c !important;
  padding: 8px 15px !important;
  background: #ffffff;
}
.header-dropdown ul li:last-child:hover a.sign-out {
  color: #003c3c;
}
.header-dropdown ul li a.sign-out {
  color: #ffffff;
}

.header-dropdown ul li:last-child {
  background: #f21167 !important;
  color: #fff !important;
}

.header-dropdown ul li:last-child:hover {
  background: #e0fbee !important;
  color: #003c3c !important;
}

.pick-dropdown .ant-select-item {
  font-weight: 500 !important;
  font-size: 20px !important;
  color: #444444 !important;
  padding: 10px 25px !important;
}

.ant-select.common {
  cursor: default;
  pointer-events: none;
}
.common .ant-select-selection-item {
  padding-left: 10px;
  font-size: 20px;
  color: #444444;
  font-weight: 700;
  padding-inline-start: 5px !important;
  /* margin-left: 8px; */
}
.common .ant-select-selection-item svg {
  padding-right: 5px;
}
.common .ant-select-selector {
  /* border-color: transparent !important; */
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  color: rgb(68, 68, 68);
  padding: 0px !important;
}
.common:hover .ant-select-selector {
  /* border-color: transparent !important; */
}
.common.ant-select-focused .ant-select-selector {
  /* border-color: transparent !important; */
  box-shadow: none !important;
}
.common .ant-select-arrow {
  inset-inline-start: 0px;
  font-size: 14px;
  color: #07a568;
}

.common .ant-select-arrow svg {
  height: 19px;
  width: 18px;
  backdrop-filter: blur(5px);
}
