/* add new popup builder */
.delete .ant-modal-content {
  border: 4px solid #4d9a9a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding: 10px;
}

.delete .ant-modal {
  width: 500px !important;
}

.delete .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-top: 1px solid rgba(34, 34, 34, 0.1);
  padding-top: 15px;
  margin-top: 15px;
}

.delete .ant-modal-footer button {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  height: 60px;
  border-radius: 12px;
  min-width: 225px;
}

.delete .ant-modal-footer button.ant-btn-default {
  background: #ff8b3d;
  border: 4px solid #ff643d;
}

.delete .ant-modal-footer button.ant-btn-primary {
  background: #f21167;
  border: 4px solid #ff5c9a;
}
.delete .ant-modal-footer button.ant-btn-default:hover {
  color: #ffffff;
}

/* start radio styles */
.role-radio {
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}
.role-checkbox {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}
.role-checkbox .ant-checkbox-wrapper {
  margin-inline-start: 0px;
  margin-bottom: 10px;
}
.role-radio label span,
.role-checkbox label span {
  color: #444444;
  font-weight: 500;
  font-size: 14px;
}

.role-radio label:hover .ant-radio-inner {
  border-color: inherit;
}

.role-radio label.ant-radio-wrapper-checked span,
.role-checkbox label.ant-checkbox-wrapper-checked span {
  color: #07a568;
}

.role-radio
  label.ant-radio-wrapper-checked
  span.ant-radio-checked
  .ant-radio-inner {
  border-color: #07a568;
  background-color: transparent;
}
.role-radio
  label.ant-radio-wrapper-checked
  span.ant-radio-checked
  .ant-radio-inner::after {
  background-color: #07a568;
}
.role-radio label span .ant-radio-inner {
  border-color: #444;
}

.role-checkbox label.ant-checkbox-wrapper .ant-checkbox-inner,
.role-checkbox label.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #444 !important;
}

.role-checkbox label.ant-checkbox-wrapper-checked .ant-checkbox-inner,
.role-checkbox label.ant-checkbox-wrapper-checked:hover .ant-checkbox-inner {
  border-color: #07a568 !important;
  background-color: #07a568 !important;
}

.role-checkbox label.ant-checkbox-wrapper-checked .ant-checkbox-checked::after,
.role-checkbox
  label.ant-checkbox-wrapper-checked:hover
  .ant-checkbox-checked::after {
  border-color: #07a568 !important;
}

/* input styles */
.delete-input {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #4d9a9a;
  border-radius: 0px;
  font-weight: 500;
  font-size: 16px;
  color: #666666;
  padding: 0px;
}
.delete-input:hover {
  border-bottom: 1px solid #4d9a9a;
}
.delete-input:focus {
  box-shadow: none;
}
/* switch styles */
.role-switch.ant-switch-checked,
.role-switch:hover.ant-switch-checked {
  background: #07a568 !important;
}

/* upload styles */
.delete-upload {
  padding: 10px 0px;
  text-align: center;
}
.delete-upload svg {
  width: 40px;
}

.delete-upload .ant-upload {
  background: #e0fbee;
  border-color: #1f5454 !important;
  border-radius: 100%;
}
